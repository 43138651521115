/* eslint-disable max-len */
/* eslint-disable react/no-multi-comp */

import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import SEO from 'components/SEO';

import felsenburgImage from './images/Felsenburg.jpg';
import joggeliImage from './images/joggeli.jpg';
import kappelenring6014Image from './images/Kappelenring6014.jpg';
import mfhBrugerawilImage from './images/MFH-Brugerawil.jpg';
import mfhWolfenackerImage from './images/MFH-Wolfenacker.jpg';
import misto028Image from './images/misto-028.jpg';
import misto030Image from './images/misto-030.jpg';
import misto031Image from './images/misto-031.jpg';
import mix030Image from './images/mix-030.jpg';
import wankdorf052Image from './images/wankdorf052.jpg';

const references = [
  {
    title: 'EFH Überbauung Haselrain Düdingen',
    image: misto028Image,
  },
  {
    title: 'MFH Überbauung Hagneck Wünnewil',
    image: misto030Image,
  },
  {
    title: 'MFH Überbauung Kastanienalle Bösingen',
    image: misto031Image,
  },
  {
    title: 'Sanierung der Fassadenfugen MFH Kappelenring 6 Hinterkappele',
    image: kappelenring6014Image,
  },
  {
    title: 'Wankdorfstadion Bern 1',
    image: wankdorf052Image,
  },
  {
    title: 'Wankdorfstadion Bern 2',
    image: mix030Image,
  },
  {
    title: 'Sankt Jakob Standion "Joggeli" in Basel',
    image: joggeliImage,
  },
  {
    title: 'Umbau der Felsenburg in Bern',
    image: felsenburgImage,
  },
  {
    title: 'MFH Überbauung Wolfenacker und Düdlingen',
    image: mfhWolfenackerImage,
  },
  {
    title: 'MFH Überbauung Brugerawil in Düdlingen',
    image: mfhBrugerawilImage,
  },
  {
    title: 'MFH Überbauung Mohnstrasse - Giessenweg in Wabern',
    image: null,
  },
  {
    title: 'MFH Überbauung in Thörishaus',
    image: null,
  },
];

const List = styled.ul``;

function ListItem(props) {
  const {
    children,
    selected,
    image,
    onClick,
  } = props;

  const handleClick = (ev) => {
    ev.preventDefault();
    if (onClick) {
      onClick();
    }
  };

  if (image) {
    const classNames = [
      'link-ish',
      selected ? 'active' : '',
    ].filter(c => !!c).join(' ');

    return (
      <li>
        <a
          className={classNames}
          href={image}
          onClick={handleClick}
        >
          {children}
        </a>
      </li>
    );
  }

  return (
    <li>
      {children}
    </li>
  );
}

const Backdrop = styled.div`
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
`;

const ModalContainer = styled.div`
  z-index: 200;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
`;

const ModalBox = styled.div`
  padding: 10px;
  background-color: #fff;
  pointer-events: all;

  @media (max-width: 500px) {
    margin: 0 10px;
  }
`;

const ModalBody = styled.div`
  position: relative;
  background-color: rgba(0, 255, 0, 0.4)
`;

const ModalImage = styled.img`
  display: block;

  @media (max-width: 500px) {
    width: 100%;
  }
`;

const ModalFooter = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0 0 0;

  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

const ModalDescription = styled.div`
  flex: 1;
  font-weight: bold;

  @media (max-width: 500px) {
    width: 100%;
  }
`;

const ModalClose = styled.div`
  cursor: pointer;

  @media (max-width: 500px) {
    padding-top: 0.6em;
    width: auto;
    text-align: right;
    align-self: flex-end;
    font-size: 0.8em;
  }
`;

const ModalNextButton = styled.div`
  position: absolute;
  display: flex;
  top: 0;
  bottom: 0;
  width: 50%;
  cursor: pointer;
  align-items: flex-start;

  transition: opacity 0.2s ease-out;

  opacity: 0;

  &:hover {
    opacity: 0.8;
  }

  ${props => props.next && css`
    justify-content: flex-end;
    left: 50%;
  `}

  ${props => props.prev && css`
    justify-content: flex-start;
    left: 0;
  `}
`;

const ModalNextButtonText = styled.div`
  padding: 7px 10px;
  font-weight: bold;
  background-color: #fff;
`;

class Lightbox extends React.Component {
  componentDidMount() {
    window.addEventListener('keyup', this.handleKeyUp, false);
  }

  componentWillUnmount() {
    window.removeEventListener('keyup', this.handleKeyUp, false);
  }

  handleKeyUp = (ev) => {
    const {
      onPrev,
      onNext,
      onClose,
    } = this.props;

    const { keyCode } = ev;
    const ESC_KEY = 27;
    const P_KEY = 80;
    const K_KEY = 75;
    const LEFT_KEY = 37;
    const N_KEY = 78;
    const J_KEY = 74;
    const RIGHT_KEY = 39;

    switch (keyCode) {
      case ESC_KEY:
        ev.preventDefault();
        onClose();
        break;
      case P_KEY:
      case K_KEY:
      case LEFT_KEY:
        ev.preventDefault();
        if (onPrev) {
          onPrev();
        }
        break;
      case N_KEY:
      case J_KEY:
      case RIGHT_KEY:
        ev.preventDefault();
        if (onNext) {
          onNext();
        }
        break;
      default:
        // nothing
    }
  };

  render() {
    const {
      item,
      onNext,
      onPrev,
      onClose,
    } = this.props;

    if (item) {
      return (
        <>
          <Backdrop onClick={onClose} />
          <ModalContainer>
            <ModalBox>
              <ModalBody>
                <ModalImage src={item.image} />
                {onPrev && (
                  <ModalNextButton prev onClick={onPrev}>
                    <ModalNextButtonText>
                      Vorheriges
                    </ModalNextButtonText>
                  </ModalNextButton>
                )}
                {onNext && (
                  <ModalNextButton next onClick={onNext}>
                    <ModalNextButtonText>
                      Nächstes
                    </ModalNextButtonText>
                  </ModalNextButton>
                )}
              </ModalBody>
              <ModalFooter>
                <ModalDescription>
                  {item.title}
                </ModalDescription>
                <ModalClose onClick={onClose}>
                  Schliessen ✗
                </ModalClose>
              </ModalFooter>
            </ModalBox>
          </ModalContainer>
        </>
      );
    }

    return null;
  }
}

function References(props) {
  const [selectedItem, selectItem] = useState(null);
  const { items } = props;

  return (
    <>
      <List>
        {items.map((item, index) => (
          <ListItem
            key={item.title}
            selected={index === selectedItem}
            image={item.image}
            onClick={item.image ? () => { selectItem(index); } : undefined}
          >
            {item.title}
            {selectedItem}
          </ListItem>
        ))}
      </List>
      <Lightbox
        item={selectedItem !== null && items[selectedItem]}
        onNext={items[selectedItem + 1] && items[selectedItem + 1].image ? () => { selectItem(selectedItem + 1); } : undefined}
        onPrev={items[selectedItem - 1] && items[selectedItem - 1].image ? () => { selectItem(selectedItem - 1); } : undefined}
        onClose={() => { selectItem(null); }}
      />
    </>
  );
}

function preloadImages(images) {
  images.forEach((image) => {
    const img = new Image();
    img.src = image;
  });
}

class ReferenzenPage extends React.Component {
  componentDidMount() {
    const imgs = references.map(r => r.image).filter(i => !!i);
    preloadImages(imgs);
  }

  render() {
    return (
      <>
        <SEO title="Referenzen" keywords={[]} />
        <h1>Referenzen</h1>
        <References items={references} />
      </>
    );
  }
}

export default ReferenzenPage;
